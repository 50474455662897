import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import LoadingScreen from '../components/loading-screen';
import PreloaderProvider from '../components/preloader/PreloaderProvider';
import OobSignInPage from '../pages/auth/OobSignInPage';
import { PATH_USER } from '../routes/paths';
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------
type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, emailVerified, adminSessionChanging } = useAuthContext();
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  if (!isInitialized || adminSessionChanging) {
    return <LoadingScreen />;
  }
  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return (
      <OobSignInPage />
    )
  }

  if (!emailVerified) {
    // we have a valid user that has not yet validated their email by means of emailed link.
    if (pathname !== PATH_USER.notVerified) {
      return <Navigate to={PATH_USER.notVerified} />;
    }
  }
  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <PreloaderProvider>{children}</PreloaderProvider>;
}