import {
	Stack
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { GAEventDateRangeChange, useAnalyticsContext } from '../../auth/analytics';
import useResponsive from '../../hooks/useResponsive';
import {
	DATE_PRESET_OPTIONS,
	DateBounds,
	MAX_RANGE_YEARS,
	PresetTimeRange,
	calculatePresetTimeRangeBounds,
	earliestPossibleDate,
	latestPossibleDate,
	maxRangeIsPlural
} from '../../utils/mrr/presetTimeRange';
import { PreloadContext } from '../preloader/PreloaderProvider';
import PresetTimeRangePickerToolbar from './PresetTimeRangePickerToolbar';


interface PresetTimeRangePickerProps {
    centered: boolean,
    defaultRange: PresetTimeRange,
    filterEndDate: Date | null,
    filterStartDate: Date | null,
    usageLocation: string,
    onTimeRangeChanged: (dateBounds: DateBounds | null) => void,
    callbackSetDatesLegal: (legal: boolean) => void
}

const verboseLogging = false;

export default function PresetTimeRangePicker(props: PresetTimeRangePickerProps) {
    const { enqueueSnackbar } = useSnackbar();

    const {
        centered,
        defaultRange,
        filterEndDate,
        filterStartDate,
        usageLocation, // location used for react-ga4 [Analytics]
        onTimeRangeChanged,
        callbackSetDatesLegal
    } = props;

    const { sendEvent } = useAnalyticsContext()

    const preloadState = useContext(PreloadContext);

    const { earliestSelectableDate } = preloadState;

    const isSmallViewport = useResponsive('down', 'sm');

    const [defaultTimeRange, setDefaultTimeRange] = useState<PresetTimeRange>(defaultRange);

    const [candidateStartDate, setCandidateStartDate] = useState<Date | null>(filterStartDate);
    const [candidateEndDate, setCandidateEndDate] = useState<Date | null>(filterEndDate);
    const [datesNotAllowedText, setDatesNotAllowedText] = useState('');
    const [dateInvalidText, setDatesInvalidText] = useState('');
    const [enableSubmit, setEnableSubmit] = useState(false);
    // this clamps the date pickers' range to the user's earliest listing.effective_date
    const [fetchFirstReport, setFetchFirstReport] = useState(true);
    const [formLoadState] = useState(false); // submit button removed by design

    // we initialize to custom, then set the default later, to initialize the controls

    //TODO: For this on-mount behavior, don't rely on 'custom'. Use null and another 'initialized' state, if you have to.

    const [valuePreset, setValuePreset] = useState<PresetTimeRange>(PresetTimeRange.Custom);

    //NOTE: This will be needed if we bring back prev/next month links. At that point, make it a prop that
    //      goes down to the chart.
    // let viewModeSubMonth = false;

    const applyPresetTimeframe = useCallback((preset: PresetTimeRange, customBounds: DateBounds | null = null) => {
        if (verboseLogging) { console.log('applying time range preset', preset); }

        if (preset === PresetTimeRange.Custom) {
            if (verboseLogging) { console.log('custom date mode'); }

            if (customBounds === null) {
                console.warn('passing null dates in custom mode');
                setEnableSubmit(false);
                onTimeRangeChanged(null);
                return;
            }

            if (customBounds.start === null || customBounds.end === null) {
                console.warn('passing null custom dates!');
                setEnableSubmit(false);
                onTimeRangeChanged(null);
                return;
            }

            onTimeRangeChanged(customBounds);
            setEnableSubmit(true);
            return;
        }

        if (verboseLogging) { console.log('NON-custom date mode'); }

        const presetBounds = calculatePresetTimeRangeBounds(preset);

        setCandidateStartDate(presetBounds.start);
        setCandidateEndDate(presetBounds.end);

        onTimeRangeChanged(presetBounds);

        setEnableSubmit(true);
    }, [onTimeRangeChanged]);

    const handleChangePreset = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (verboseLogging) { console.log('changing preset to', event.target.value); }
        setValuePreset(event.target.value as PresetTimeRange);
        applyPresetTimeframe(event.target.value as PresetTimeRange);
        callbackSetDatesLegal(true);
        setDatesNotAllowedText('');
        sendEvent(new GAEventDateRangeChange(usageLocation, event.target.value, true))
    };

    const handleRequestReport = () => {
        // Feature disabled by design (no submit button; we refresh on every input change)
    };

    const resetDateInputs = useCallback(() => {
        if (verboseLogging) { console.log('reset date inputs'); }
        setValuePreset(defaultTimeRange);
        applyPresetTimeframe(defaultTimeRange);
        callbackSetDatesLegal(true);
        setDatesNotAllowedText('');
    }, [applyPresetTimeframe, callbackSetDatesLegal, defaultTimeRange]);

    const setCustomDateModeAndValidate = (start: Date | null, end: Date | null) => {
        if (verboseLogging) { console.log('set custom date mode and validate', start, end); }

        setValuePreset(PresetTimeRange.Custom);

        // now validate the inputs

        if (start === null || end === null) {
            console.warn('date missing on validate', start, end);
            setEnableSubmit(false);
            return;
        }

        if (Number.isNaN(start.getTime())) {
            setDatesNotAllowedText('Start date is invalid.');
            setEnableSubmit(false);
            return;
        }

        if (Number.isNaN(end.getTime())) {
            setDatesNotAllowedText('End date is invalid.');
            setEnableSubmit(false);
            return;
        }

        const timeEnd = (new Date(end.getFullYear(), end.getMonth(), end.getDate())).getTime();
        const timeStart = (new Date(start.getFullYear(), start.getMonth(), start.getDate())).getTime();

        if (verboseLogging) { console.log('custom times', timeStart, timeEnd); }

        const datesAreOrdered = timeStart <= timeEnd;

        //TODO: Move this to a dateFns helper

        const rangeInYears = end.getFullYear() - start.getFullYear();

        let datesRangeIsLegal = rangeInYears <= MAX_RANGE_YEARS;

        if (datesRangeIsLegal && rangeInYears === MAX_RANGE_YEARS) {
            // if we're on the nose, we need to check the sub-year components
            if (start.getMonth() > end.getMonth()) {
                // we're good; not a full wrapped year
                if (verboseLogging) { console.log('wrapped year ok: month'); }
            }
            else if (start.getMonth() === end.getMonth()) {
                if (start.getDate() > end.getDate()) {
                    // we're good; not a full wrapped year
                }
                else if (start.getDate() === end.getDate()) {
                    if (verboseLogging) { console.log('on-the-nose failure'); }
                    datesRangeIsLegal = false;
                }
                else {
                    if (verboseLogging) { console.log('wrapped year FAIL: date'); }
                    datesRangeIsLegal = false;
                }
            }
            else {
                if (verboseLogging) { console.log('wrapped year FAIL: month'); }
                datesRangeIsLegal = false;
            }
        }

        if (!datesAreOrdered) {
            if (verboseLogging) { console.log('out of order'); }
            setDatesNotAllowedText('Start date is after end date.');
        }
        else if (!datesRangeIsLegal) {
            if (verboseLogging) { console.log('out of range', rangeInYears); }
            setDatesNotAllowedText('Reports are limited to ' + MAX_RANGE_YEARS
                + ' year' + (maxRangeIsPlural ? 's' : '') + '.');
        }
        else {
            setDatesNotAllowedText('');
        }

        //NOTE: We have three separate rules to block submit:
        //      - dateInvalidText: meaning the date inputs are broken, e.g. "5/24/abc"
        //      - datesAreOrdered: start must be on or before end
        //      - datesRangeIsLegal: we only X years worth of report data

        const legalDates = dateInvalidText === '' && datesAreOrdered && datesRangeIsLegal;

        setEnableSubmit(legalDates);

        // inform the parent
        callbackSetDatesLegal(legalDates);

        if (!legalDates) {
            // console.warn('selected dates are not allowed');
            return;
        }
        const customBounds = { start: start, end: end };

        applyPresetTimeframe(PresetTimeRange.Custom, customBounds);
    };

    const formInputValid =
        filterEndDate !== null
        && filterStartDate !== null
        && dateInvalidText === ''
        && datesNotAllowedText === '';

    if (verboseLogging) { console.log('pre report', filterEndDate, filterStartDate, dateInvalidText, datesNotAllowedText); }

    // This use effect handles form initialization, once the listings are available. The date pickers have to
    // wait for listings, because their first/last available dates are driven by listings' effective_date.
    useEffect(() => {
        if (!fetchFirstReport) {
            if (verboseLogging) { console.log('first report already fetched'); }
            return;
        }

        if (!formInputValid) {
            if (filterEndDate === null && filterStartDate === null) {
                // Now that we're ready, set the initial UI. This gets the first chart (without a double request).
                // console.log('setting initial UI (which will trigger initial chart fetch)');
                setFetchFirstReport(false); // one time only
                resetDateInputs();
            }

            // console.log('did not reset due valid date inputs');
        }

        // console.log('form input valid');
    }, [fetchFirstReport, filterEndDate, filterStartDate, formInputValid, resetDateInputs]);

    return (
        <Stack p={2} pt={2.5}>
            <PresetTimeRangePickerToolbar
                earliestSelectableDate={earliestSelectableDate === null ? earliestPossibleDate : earliestSelectableDate}
                latestSelectableDate={latestPossibleDate}
                notAllowedText={datesNotAllowedText}
                errorText={dateInvalidText}
                setErrorText={setDatesInvalidText}
                enableSubmit={enableSubmit && !formLoadState} // submit button removed by design
                reportBeingLoaded={formLoadState}             // submit button removed by design
                filterEndDate={candidateEndDate}
                filterStartDate={candidateStartDate}
                optionsPreset={DATE_PRESET_OPTIONS}
                valuePreset={valuePreset}
                // eslint-disable-next-line react/destructuring-assignment
                centered={isSmallViewport || centered}
                onChangePreset={handleChangePreset}
                onFilterEndDate={(newValue) => {
                    verboseLogging && console.log('changed: end', newValue, ' existing start: ' + candidateStartDate);
                    setCandidateEndDate(newValue);
                    setCustomDateModeAndValidate(candidateStartDate, newValue);
                    const isValidDate = datesNotAllowedText === ''
                    sendEvent(new GAEventDateRangeChange(usageLocation, 'custom', isValidDate, 'end'))
                }}
                onFilterStartDate={(newValue) => {
                    verboseLogging && console.log('changed: start', newValue, ' existing end: ' + candidateEndDate);
                    setCandidateStartDate(newValue);
                    setCustomDateModeAndValidate(newValue, candidateEndDate);
                    const isValidDate = datesNotAllowedText === ''
                    sendEvent(new GAEventDateRangeChange(usageLocation, 'custom', isValidDate, 'start'))
                }}
                onRequestReport={() => {
                    handleRequestReport();
                }}
                onResetFilter={() => {
                    resetDateInputs();
                }}
            />
        </Stack>
    );
}
