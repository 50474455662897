import {
  Box,
  Button,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ConfirmDialog from '../../../components/confirm-dialog';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  getComparator,
  useTable
} from '../../../components/table';
import TableLoading from '../../../components/table/TableLoading';
import { useDynamicTableHeaders } from '../../../components/table/useDynamicTableHeaders';
import { CalendarReservationContentMode } from '../../../models/mrr/calendarEvents';
import { ReservationGeneral, ReservationGuestType } from '../../../models/mrr/reservationGeneral';
import { useGetRTKQReservationsQuery } from '../../../redux/rtkQuery/apiSlice';
import { useTableWidth } from '../useTableWidth';
import ReservationTableRow from './ReservationTableRow';
import ReservationTableToolbar from './ReservationTableToolbar';
import {
  createReservationTableHead,
  defaultStageFilter,
  desktopBreakpointKeys,
  mobileBreakpointKeys,
  tabletBreakpointKeys
} from './config';
import PresetTimeRangePicker from '../../../components/date-range-picker/PresetTimeRangePicker';
import { DateBounds, PresetTimeRange } from '../../../utils/mrr/presetTimeRange';

interface ReservationTableProps {
  listingId?: string;
  mode?: CalendarReservationContentMode;
}
// ----------------------------------------------------------------------

export function ReservationTable(props: ReservationTableProps) {
  const { listingId, mode = CalendarReservationContentMode.All } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    selected,
    onSelectRow,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage
  } = useTable({
    defaultDense: true,
    defaultOrder: 'desc',
    defaultOrderBy: 'booking_date_ISO'
  });

  const [dynamicTableHeaders, tableContentColumnsToShow] =
    useDynamicTableHeaders<ReservationGeneral>({
      tableHeaders: createReservationTableHead(mode),
      breakpoints: {
        sm: mobileBreakpointKeys,
        md: tabletBreakpointKeys,
        lg: desktopBreakpointKeys
      }
    });

  const tableMinWidth = useTableWidth();

  const [resvQueryStartDate, setResvQueryStartDate] = useState<Date | null>(null);
  const [resvQueryEndDate, setResvQueryEndDate] = useState<Date | null>(null);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterUnitId, setFilterUnitId] = useState('all');
  const [filterGuestType, setFilterGuestType] = useState('all');

  //TODO: Not in use; to be repurposed for other filters (see User List).
  // const [filterRole, setFilterRole] = useState('all');
  //NOTE: Any refresh-button feature would need to reset this to null. Otherwise, the
  //      options shown in the filter may not match the data.
  const [filterOptionsUnitId, setFilterOptionsUnitId] = useState<null | string[]>(null);
  const [filterGuestTypeOptions, setFilterGuestTypeOptions] = useState<null | string[]>(null);
  const [filterStage, setFilterStage] = useState(defaultStageFilter);
  const [legalDates, setLegalDates] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);

  const isFiltered =
    filterSearch !== '' ||
    filterUnitId !== 'all' ||
    filterGuestType !== 'all' ||
    filterUnitId !== 'all' ||
    filterStage !== defaultStageFilter;

  const {
    data: reservationData,
    // error: reservationsError,
    isFetching,
    isSuccess
    // isError
  } = useGetRTKQReservationsQuery({
    listingId: listingId,
    startDate: resvQueryStartDate,
    endDate: resvQueryEndDate
  });

  useEffect(() => {
    //? set default guest type filter from search params
    if (filterGuestTypeOptions === null) {
      return;
    }
    if (!searchParams.has('guest_type')) {
      return;
    }
    const guest_type = searchParams.get('guest_type')!;
    if (filterGuestTypeOptions.includes(guest_type)) {
      setFilterGuestType(guest_type);
    }
    setSearchParams(new URLSearchParams());
  }, [searchParams, filterGuestTypeOptions, setSearchParams]);

  const dataFiltered = applyReservationTableFilter({
    inputData: isSuccess ? reservationData : [],
    comparator: getComparator(order, orderBy),
    filterSearch,
    filterUnitId,
    filterStage,
    filterGuestType
  });
  // const denseHeight = dense ? 52 : 72;

  const anyFilterInUse = filterSearch !== '' || filterUnitId !== 'all' || filterGuestType !== 'all';

  const dataToShowExists = dataFiltered.length > 0;

  const handleOpenConfirm = () => {
    //REMINDER: This only pertains to _our_ confirm, not one opened by one of our table rows!
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    //REMINDER: This only pertains to _our_ confirm, not one opened by one of our table rows!
    setOpenConfirm(false);
  };

  // const handleFilterStage = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
  //     setPage(0);
  //     setFilterStage(newValue);
  // };

  const handleFilterSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterSearch(event.target.value);
  };

  const handleFilterUnitId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterUnitId(event.target.value);
  };

  const handleFilterGuestType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterGuestType(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterSearch('');
    setFilterUnitId('all');
    setFilterGuestType('all');
    setFilterStage(defaultStageFilter);
  };

  if (isSuccess) {
    if (filterOptionsUnitId === null) {
      //! build the dynamic Unit Id filter options, once we have data
      const allUnitIds = reservationData.map((resv) => resv.unit_id);
      const uniqueIds = new Set(allUnitIds);
      if (uniqueIds.has('all')) {
        uniqueIds.delete('all');
      }
      const uniqueIdArray: string[] = Array.from(uniqueIds);
      uniqueIdArray.unshift('all');
      setFilterOptionsUnitId(uniqueIdArray);
    }
    if (filterGuestTypeOptions === null) {
      //! build the guest type filter options, once we have data
      const allGuestTypes = reservationData.map((resv) => resv.guest_type);
      const uniqueGuestTypes = new Set(allGuestTypes);
      const uniqueGuestTypeArray = Array.from(uniqueGuestTypes) as (ReservationGuestType | 'all')[];
      uniqueGuestTypeArray.unshift('all');
      setFilterGuestTypeOptions(uniqueGuestTypeArray);
    }
  }
  return (
    <>
      {/* TODO: Replicate the structure of "ReservationTableToolbar", to use existing styles (no hard-coded 4px) */}
      <Box p="4px" pt={2.5}>
        <PresetTimeRangePicker
          centered={false}
          defaultRange={PresetTimeRange.Future}
          filterEndDate={resvQueryEndDate}
          filterStartDate={resvQueryStartDate}
          usageLocation="reservations"
          onTimeRangeChanged={(dateBounds: DateBounds | null) => {
            // console.log('reservation table time range changed', dateBounds);

            if (dateBounds === null) {
              setResvQueryStartDate(null);
              setResvQueryEndDate(null);
              return;
            }

            setResvQueryStartDate(dateBounds.start);
            setResvQueryEndDate(dateBounds.end);
          }}
          callbackSetDatesLegal={(legal: boolean) => {
            setLegalDates(legal);
          }}
        />
      </Box>

      <Divider />

      {!legalDates ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '80px'
          }}
        >
          <Typography variant="h3" paragraph>
            Invalid date range.
          </Typography>

          <Typography sx={{ color: 'text.secondary' }}>
            Please check the start and end dates.
          </Typography>
        </div>
      ) : (
        <>
          {/* TODO: Instead of hiding, test the filter dropdown's built-in loading state */}
          <ReservationTableToolbar
            singleListing={mode === CalendarReservationContentMode.SingleListing}
            filterSearch={filterSearch}
            filterUnitId={filterUnitId}
            isFiltered={anyFilterInUse}
            optionsUnitId={filterOptionsUnitId || ['all']}
            onFilterSearch={handleFilterSearch}
            onFilterUnitId={handleFilterUnitId}
            onResetFilter={handleResetFilter}
            onFilterGuestType={handleFilterGuestType}
            filterGuestType={filterGuestType}
            optionsGuestType={filterGuestTypeOptions || ['all']}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={dataFiltered.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  dataFiltered.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={handleOpenConfirm}>
                    <Iconify icon="eva:trash-2-outline" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: tableMinWidth }}>
                <TableHeadCustom
                  usageTable="reservation"
                  order={order}
                  orderBy={orderBy}
                  headLabel={dynamicTableHeaders}
                  rowCount={dataFiltered.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      dataFiltered.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <ReservationTableRow
                        mode={mode}
                        key={row.id + '-' + i}
                        // isSessionBooking={AUTH.currentbooking?.uid === row.id}
                        row={row}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onDeleteRow={() => {
                          console.warn('Delete single reservation disabled.');
                        }}
                        showColumns={tableContentColumnsToShow}
                      />
                    ))}

                  {/* <TableEmptyRows
                                    height={denseHeight}
                                    emptyRows={emptyRows(page, rowsPerPage, dataFiltered.length)} /> */}

                  {isFetching ? (
                    <TableLoading isNotFound={!dataToShowExists} />
                  ) : (
                    <TableNoData isNotFound={!dataToShowExists} isFiltered={isFiltered} />
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            showDenseToggle={false}
            dense={dense}
            onChangeDense={onChangeDense}
          />
          <ConfirmDialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            title="Delete"
            content={
              <>
                Are you sure you want to delete <strong> {selected.length} </strong> booking
                {selected.length === 1 ? '' : 's'}?
              </>
            }
            action={
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  console.warn('Delete multiple reservations disabled.');
                }}
              >
                Delete
              </Button>
            }
          />
        </>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

export function applyReservationTableFilter({
  inputData,
  comparator,
  filterSearch,
  filterUnitId,
  filterStage,
  filterGuestType
}: {
  inputData: ReservationGeneral[];
  comparator: (a: any, b: any) => number;
  filterSearch: string;
  filterUnitId: string;
  filterStage: string;
  filterGuestType: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterSearch) {
    const filterSearchLower = filterSearch.toLowerCase();
    inputData = inputData.filter((reservation) => {
      return (
        reservation.unit_id.toLowerCase().indexOf(filterSearchLower) !== -1 ||
        reservation.guest_name.toLowerCase().indexOf(filterSearchLower) !== -1 ||
        reservation.name.toLowerCase().indexOf(filterSearchLower) !== -1 ||
        reservation.ui_table_guest_name.toLowerCase().indexOf(filterSearchLower) !== -1
      );
    });
  }

  // this filter is not in use, but it has come and gone; could be back
  // if (filterStage !== 'all') {
  //     inputData = inputData.filter((reservation) => reservation.stage_mapped === filterStage);
  // }

  if (filterUnitId !== 'all') {
    const filterUnitIdLower = filterUnitId.toLowerCase();
    inputData = inputData.filter((reservation) => {
      if (!reservation.unit_id) {
        return false;
      }
      return reservation.unit_id.toLowerCase() === filterUnitIdLower;
    });
  }

  if (filterGuestType !== 'all') {
    const filterGuestTypeLower = filterGuestType.toLowerCase();
    inputData = inputData.filter((reservation) => {
      if (!reservation.guest_type) {
        return false;
      }
      return reservation.guest_type.toLowerCase() === filterGuestTypeLower;
    });
  }

  return inputData;
}
