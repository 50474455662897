import { ONE_DAY_MS } from "../formatTime";

export const urlKeyReportRange = 'dateRange';

export enum PresetTimeRange {
    //NOTE: Order here is important! Drop-downs use these directly.
    Future = 'Future Dates',
    YearToDate = 'Year to Date',
    LastYear = 'Last Year',
    CurrentYear = 'This Year',
    NextYear = 'Next Year',
    LastMonth = 'Last Month',
    CurrentMonth = 'This Month',
    NextMonth = 'Next Month',
    Custom = 'Custom Range',
};

export const DATE_PRESET_OPTIONS: string[] = Object.values(PresetTimeRange);
export const DATE_PRESET_MAP = new Map<string, string>();

// Build a map version from this enum, e.g. for reverse lookups of url-param.

// eslint-disable-next-line no-restricted-syntax
for (const key in PresetTimeRange) {
	// eslint-disable-next-line no-prototype-builtins
	if ((PresetTimeRange as any).hasOwnProperty(key)) {
		const value = (PresetTimeRange as any)[key];
		if (typeof value === 'string') {
			DATE_PRESET_MAP.set(key, value);
		}
	}
}

export const MAX_RANGE_YEARS = 1;
export const maxRangeIsPlural = (MAX_RANGE_YEARS as any) !== 1;
export const earliestPossibleDate = new Date(2000, 0, 1);
export const latestPossibleDate = new Date((new Date()).getFullYear() + 1, 11, 31);

export type DateBounds = { start: Date, end: Date };

export function calculatePresetTimeRangeBounds(preset: PresetTimeRange): DateBounds {
    const dateNow = new Date();
    const thisYear = dateNow.getFullYear();
    const thisMonth = dateNow.getMonth();
    const dateFirstOfThisYear = new Date(thisYear, 0, 1);
    const thisIsDecember = thisMonth >= 11;
    const dateFirstOfNextMonth = thisIsDecember
        ? new Date(thisYear + 1, 0, 1)
        : new Date(thisYear, thisMonth + 1, 1);
    const dateFirstOfThisMonth = new Date(thisYear, thisMonth, 1);
    const dateLastOfThisYear = new Date(thisYear, 11, 31);
    const dateLastOfLastMonth = new Date(dateFirstOfThisMonth.getTime() - ONE_DAY_MS);

    let outgoingStart!: Date;
    let outgoingEnd!: Date;

    switch (preset) {
        case PresetTimeRange.CurrentMonth: {
            const dateLastOfThisMonth = new Date(dateFirstOfNextMonth.getTime() - ONE_DAY_MS);

            outgoingStart = dateFirstOfThisMonth;
            outgoingEnd = dateLastOfThisMonth;
        }
        break;

        case PresetTimeRange.CurrentYear: {
            outgoingStart = dateFirstOfThisYear;
            outgoingEnd = dateLastOfThisYear;
        }
        break;

        case PresetTimeRange.Custom: {
            // there is no point to calling this helper for custom
            throw new Error('Invalid use of Custom time range preset');
        }

        case PresetTimeRange.Future: {
            //NOTE: Alternate bounds; could come back, or become another entry in the enum.
            // outgoingStart = dateFirstOfThisMonth;
            // outgoingEnd = dateLastOfThisYear;
            outgoingStart = dateNow;
            outgoingEnd = new Date(dateNow.getTime() + 364 * ONE_DAY_MS);
        }
        break;

        case PresetTimeRange.LastMonth: {
            const dateFirstOfLastMonth = new Date(dateLastOfLastMonth.getFullYear(),
                dateLastOfLastMonth.getMonth(),
                1);

            outgoingStart = dateFirstOfLastMonth;
            outgoingEnd = dateLastOfLastMonth;
        }
        break;

        case PresetTimeRange.LastYear: {
            const dateFirstOfLastYear = new Date(thisYear - 1, 0, 1);
            const dateLastOfLastYear = new Date(thisYear - 1, 11, 31);

            outgoingStart = dateFirstOfLastYear;
            outgoingEnd = dateLastOfLastYear;
        }
        break;

        case PresetTimeRange.NextMonth: {
            const thisIsNovemberOrLater = thisMonth >= 10;
            const dateFirstOfTwoMonthsFromNow = thisIsNovemberOrLater
                ? new Date(thisYear + 1, thisMonth + 2 - 12, 1)
                : new Date(thisYear, thisMonth + 2, 1)
            const dateLastOfNextMonth = new Date(dateFirstOfTwoMonthsFromNow.getTime() - ONE_DAY_MS);

            outgoingStart = dateFirstOfNextMonth;
            outgoingEnd = dateLastOfNextMonth;
        }
        break;

        case PresetTimeRange.NextYear: {
            const dateFirstOfNextYear = new Date(thisYear + 1, 0, 1);
            const dateLastOfNextYear = new Date(thisYear + 1, 11, 31);

            outgoingStart = dateFirstOfNextYear;
            outgoingEnd = dateLastOfNextYear;
        }
        break;

        case PresetTimeRange.YearToDate: {
            outgoingStart = dateFirstOfThisYear;
            outgoingEnd = dateLastOfLastMonth;
        }
        break;

        default: {
            throw new Error('Unknown time range preset: ' + preset)
        }
    }

    if (!outgoingStart || !outgoingEnd) {
        throw new Error('Failed to set time range bound; preset: ' + preset + ' - '
                        + outgoingStart + ', ' + outgoingEnd);
    }

    return {
        start: outgoingStart,
        end: outgoingEnd
    }
};
