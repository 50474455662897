import { CheckLocalDev } from 'src/config-global';
import { BrandCode } from './brandConfig';

const domainBVR = 'owner.beachviewrentals.com';
const domainMRR = 'owner.mauiresortrentals.com';

export function checkClaimsForHost(claimedCode: string): boolean {
  // handle special dev hosts, first
  if (CheckLocalDev()) {
    return true;
  }
  if (window.location.hostname === 'remote-dev.owner-portal-v2.pages.dev') {
    switch (claimedCode) {
      case BrandCode.BVR:
        return process.env.REACT_APP_HOSTNAME === domainBVR;
      case BrandCode.MRR:
        return process.env.REACT_APP_HOSTNAME === domainMRR;
      default:
        return false;
    }
  }
  switch (claimedCode) {
    case BrandCode.BVR:
      return window.location.hostname === domainBVR;
    case BrandCode.MRR:
      return window.location.hostname === domainMRR;
    default:
      return false;
  }
}
